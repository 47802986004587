import { START_LOADING, STOP_LOADING, SHOW_TOAST, STOP_TOAST, VENDOR_LOGOUT } from "./types";
export const BASE_URL = 'https://admin.gifteffects.com/v1';
// export const BASE_URL = 'https://uat.gifteffects.com/v1';
// export const BASE_URL = '/v1';
//export const BASE_URL = 'http://localhost:8082/v1';
const DEFAULT_HEADERS = {'Accept':'application/json', 'Content-Type':'application/json'};

async function invokeAPI(endpoint, token, config) {

    const headers = token ? { ...DEFAULT_HEADERS, 'Authorization': `Bearer ${token}` } : {...DEFAULT_HEADERS};
    const updatedConfig = {...config, headers };
    const response = await fetch(BASE_URL + endpoint, updatedConfig);
    const body = await response.json();
    if (response.status >= 400) {
        if(response.status === 401){
            window.location = `/login`
            return
        }
        // Handle API Error and Global Errors and field level Errors
        let errorKeys = Object.keys(body).filter(key => key !== 'code')
        if(errorKeys.includes('__globals')){
            throw new Error(body.__globals[0])
        } else {
            throw new Error(body[errorKeys[0]])
        }
        //TODO: Fix this error so we can send errors from API directly.
        throw new Error(body.errors.map(error => Object.values(error))[0] || "Something went wrong");
    }
    return {body, currencyISO: response.headers.get('currencyISO'), vendorBlocked:response.headers.get('vendorBlocked'), notificationCount:response.headers.get('notificationCount')};    
}

export const CALL_API = Symbol('Call API')

export default store => next => async action => {

  // So the middleware doesn't get applied to every single action
  if (typeof action[CALL_API] === 'undefined') {
    return next(action)
  }

  let { url, method = "GET", types, showLoading = false, body = undefined, params, showMessage = null} =  action[CALL_API]

  if (body) {
      method = "POST"
  }
  const [ requestType, successType, errorType ] = types

  const { auth } = store.getState();
  next({type: requestType});
  try {
      if (showLoading) {
          next({type:START_LOADING})
      }
      const queryParams = new URLSearchParams();
      for (let param in params) {
          if (params[param]) {
              queryParams.set(param, params[param]);
          }
      }
      const updatedUrl = (url.includes(`?`) ? url : url+'?'+queryParams.toString()) + `&vendorId=${auth.currentVendor.id}`
      const responseBody = await invokeAPI(updatedUrl, auth.token, { method, body: JSON.stringify(body)});
      var notificationCount = responseBody.notificationCount
      var currencyISO = responseBody.currencyISO
      if(["true", true].includes(responseBody.vendorBlocked)){
          alert(`Vendor blocked`)
          next({type:VENDOR_LOGOUT})
      }
      next({
        ...responseBody.body,
        type: successType
      });
      if(method === 'POST' && showMessage){
          next({type:SHOW_TOAST, message:showMessage})
          setTimeout(() => {
            next({type:STOP_TOAST})
          }, 3000);
      }
      return responseBody
  } catch (error) {
        // return
    throw error;
  } finally {
    if (showLoading) {
        next({type:STOP_LOADING, notificationCount, currencyISO})
    }
  }
}
